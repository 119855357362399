import { MrbBaseViewStore } from "mrb/core/stores";
import { action, computed, makeObservable } from "mobx";
import { MrbSelectStore } from "mrb/components/select";
import { UUID } from "common/utils";
import { find, findIndex, isNil, isEmpty, filter } from "lodash";
import { nakiRadioSystemTypes } from "common/constants";

class NakiMobileHomeSectionViewStore extends MrbBaseViewStore {
    @computed get item() {
        return find(this.nakiMobileHomeSectionListViewStore.sections, (section) => section.id === this.id);
    }

    @computed get isAddContentButtonDisabled() {
        return isNil(this.contentSelectStore.selectedItem) || !this.item.isActive || this.selectedItemExists;
    }

    @computed get isSystemSection() {
        return !isEmpty(this.item.systemSectionIdentifier);
    }

    @computed get selectedItemExists() {
        const selectedItem = this.contentSelectStore.selectedItem;
        if (!selectedItem) {
            return false;
        }

        return (
            findIndex(
                this.item.items,
                (x) =>
                    /* eslint-disable eqeqeq */
                    x.stationId == selectedItem.stationId &&
                    x.podcastId == selectedItem.podcastId &&
                    x.nakiNigunArtistId == selectedItem.artistId &&
                    x.nakiNigunAlbumId == selectedItem.albumId
            ) !== -1
        );
    }

    constructor(rootStore, { nakiMobileHomeSectionListViewStore, id }) {
        super(rootStore);
        this.nakiMobileHomeSectionListViewStore = nakiMobileHomeSectionListViewStore;
        this.routeStore = this.nakiMobileHomeSectionListViewStore.routeStore;
        this.id = id;
        makeObservable(this);

        this.contentSelectStore = new MrbSelectStore({
            isSearchable: true,
            textKey: "title",
            valueKey: "id",
            initFetch: false,
            actions: {
                find: (searchTerm, pageNumber, pageSize) => {
                    return this.routeStore.getContent({
                        systemType: nakiRadioSystemTypes.mobile,
                        search: searchTerm,
                        pageNumber: pageNumber,
                        pageSize: pageSize,
                    });
                },
                mapItem: (item) => {
                    return {
                        label: item.title,
                        value: item.stationId || item.podcastId || item.artistId || item.albumId,
                        item: item,
                    };
                },
            },
        });
    }

    @action.bound
    setIsActive(value) {
        this.nakiMobileHomeSectionListViewStore.toggleIsActive(this.id, value);
    }

    @action.bound
    onTitleChange(event) {
        this.item.title = event.target.value;
    }

    addItem(item) {
        this.item.items.push(item);
    }

    @action.bound
    onSelectItem() {
        const item = this.contentSelectStore.selectedItem;
        const obj = {
            id: UUID.create(),
            sortOrder: this.item.items.length,
        };
        if (!isNil(item.stationId)) {
            obj.stationId = item.stationId;
            obj.station = {
                id: item.stationId,
                title: item.title,
                isPremium: item.isPremium,
                coverImageId: item.coverImageId,
            };
        } else if (!isNil(item.podcastId)) {
            obj.podcastId = item.podcastId;
            obj.podcast = {
                id: item.podcastId,
                title: item.title,
                isPremium: item.isPremium,
                coverImageId: item.coverImageId,
                podcastType: item.podcastType,
            };
        } else if (!isNil(item.artistId)) {
            obj.nakiNigunArtistId = item.artistId;
            obj.artist = {
                id: item.artistId,
                name: item.title,
                isPremium: item.isPremium,
                coverImageId: item.coverImageId,
            };
        } else if (!isNil(item.albumId)) {
            obj.nakiNigunAlbumId = item.albumId;
            obj.album = {
                id: item.albumId,
                title: item.title,
                isPremium: item.isPremium,
                coverImageId: item.coverImageId,
            };
        }
        if (this.itemExists(obj)) {
            return;
        }
        this.addItem(obj);
        this.contentSelectStore.clearOptions();
        this.contentSelectStore.clear();
        this.contentSelectStore.filter();
    }

    itemExists(item) {
        if (!item) {
            return false;
        }
        return (
            findIndex(
                this.item.items,
                (x) =>
                    /* eslint-disable eqeqeq */
                    x.stationId == item.stationId &&
                    x.podcastId == item.podcastId &&
                    x.nakiNigunArtistId == item.nakiNigunArtistId &&
                    x.nakiNigunAlbumId == item.nakiNigunAlbumId
            ) !== -1
        );
    }

    @action.bound
    deleteSection() {
        this.nakiMobileHomeSectionListViewStore.deleteSection(this.id);
    }

    @action.bound
    deleteItem(id) {
        this.item.items = filter(this.item.items, (item) => item.id !== id);
    }

    @action.bound
    goToGenreContent() {
        this.rootStore.routerStore.goTo("master.administration.genre.content", {
            id: this.item.genreId,
        });
    }
}

export default NakiMobileHomeSectionViewStore;
