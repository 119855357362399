import React from "react";
import { PropTypes } from "prop-types";
import { AnalyticsLayout } from "administration/analytics/layouts";
import { TrendingListItem, TopTrendingListViewMoreLoader } from "administration/analytics/components";
import { MrbInfiniteScrollGrid } from "mrb/components/infinite-scroll-grid";
import { defaultTemplate } from "common/hoc";
import { StatsWidgetEmptyState } from "common/components/empty-state";
import { MrbRadioButtons } from "baasic-react-bootstrap/src";

function TrendingStationListTemplate({ trendingStationListViewStore, t }) {
    const { analyticsTimeRangeViewStore, gridStore, filterOptions, selectedFilterOption, setFilterOption } =
        trendingStationListViewStore;
    return (
        <AnalyticsLayout analyticsTimeRangeViewStore={analyticsTimeRangeViewStore} t={t}>
            <div className="c-container--base">
                <h1 className="u-type--base u-mar--bottom--sml">Trending stations list</h1>
                <div className="u-display--flex u-display--flex--ai--center u-display--flex--jc--space-between u-mar--bottom--sml">
                    <MrbRadioButtons
                        id="trending-stations-filter"
                        name="trending-stations-filter"
                        inputClassNameExtend="c-segmented--vertical"
                        options={filterOptions}
                        onChange={setFilterOption}
                        value={selectedFilterOption}
                        labelClassNameOverride="u-type--sml"
                    />
                </div>
                <div className="c-card--primary">
                    <div className="c-analytics__header">
                        <span className="c-analytics__header__item c-analytics__item--position">Position</span>
                        <span className="c-analytics__header__item c-analytics__item--name">Station</span>
                        <span className="c-analytics__header__item c-analytics__item--prev-position">
                            Previous Position
                        </span>
                        <span className="c-analytics__header__item c-analytics__item--score">Playbacks</span>
                    </div>
                    <MrbInfiniteScrollGrid
                        loaderRenderer={() => <TopTrendingListViewMoreLoader noButton />}
                        loadMoreLoader={<TopTrendingListViewMoreLoader loadMore row={3} noButton />}
                        emptyStateRenderer={() => (
                            <StatsWidgetEmptyState classNameExtend="u-padd--top--lrg u-padd--bottom--lrg" />
                        )}
                        classNameOverride=""
                        store={gridStore}
                        cellRenderer={(props) => <TrendingListItem {...props} />}
                    />
                </div>
            </div>
        </AnalyticsLayout>
    );
}

TrendingStationListTemplate.propTypes = {
    trendingStationListViewStore: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

export default defaultTemplate(TrendingStationListTemplate);
