import React from "react";
import { observer } from "mobx-react";
import { TrendingNakiNigunAlbumListTemplate } from "themes/administration/analytics/pages";
import { TrendingNakiNigunAlbumListViewStore } from "administration/analytics/stores";
import { setCurrentView } from "mrb/core";

@setCurrentView(
    (rootStore, componentProps) => new TrendingNakiNigunAlbumListViewStore(rootStore, componentProps),
    "trendingNakiNigunAlbumListViewStore"
)
@observer
class TrendingNakiNigunAlbumList extends React.Component {
    render() {
        return <TrendingNakiNigunAlbumListTemplate {...this.props} />;
    }
}

export default TrendingNakiNigunAlbumList;
