import React from "react";
import { PropTypes } from "prop-types";
import { PortalDetailedActivity } from "administration/analytics/components";
import { MrbRouterLink } from "mrb/core/infrastructure/router";
import { mergeCss } from "mrb/common/utils";
import { defaultTemplate } from "common/hoc";

function NakiNigunAlbumActivityTemplate({ nakiNigunAlbumActivityViewStore, t }) {
    return (
        <PortalDetailedActivity
            store={nakiNigunAlbumActivityViewStore}
            activityLabel="Naki Nigun album playbacks over period"
            totalLabel="Total # of playbacks"
            nameFieldRenderer={(props) => <NakiNigunAlbumNameField {...props} />}
            t={t}
        />
    );
}

NakiNigunAlbumActivityTemplate.propTypes = {
    nakiNigunAlbumActivityViewStore: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

function NakiNigunAlbumNameField({ summaryData }) {
    let route, id, albumId;

    route = "master.administration.naki-nigun.artist.preview";
    id = summaryData.nakiNigunAlbum.artist.id;
    albumId = summaryData.nakiNigunAlbum.id;

    return (
        <React.Fragment>
            <p className="u-type--sml u-type--color--opaque">Artist</p>
            {summaryData?.nakiNigunAlbum?.artist?.name && (
                <p className="u-display--flex u-display--flex--ai--center u-mar--bottom--sml">
                    {summaryData.nakiNigunAlbum.artist.name}
                </p>
            )}
            <p className="u-type--sml u-type--color--opaque">Album</p>
            <p className="u-display--flex u-display--flex--ai--center">
                <MrbRouterLink
                    routeName={route}
                    params={{ id: id }}
                    queryParams={{ albumId: albumId }}
                    className="c-analytics__list__title c-analytics__list__title--lrg"
                >
                    {" "}
                    <p className="u-display--flex u-display--flex--ai--center">
                        {summaryData.nakiNigunAlbum.title}{" "}
                        <i
                            className={mergeCss(
                                "u-icon u-icon--base u-mar--left--tny",
                                summaryData.nakiNigunAlbum && summaryData.nakiNigunAlbum.isPremium
                                    ? "u-icon--premium"
                                    : null
                            )}
                        />{" "}
                    </p>
                </MrbRouterLink>
            </p>
        </React.Fragment>
    );
}

export default defaultTemplate(NakiNigunAlbumActivityTemplate);
